@tailwind base;
@tailwind components;
@tailwind utilities;

.btn-primary {
  @apply pointer-events-auto rounded-md bg-amber-400 py-2 px-3 leading-5 text-black hover:bg-amber-300;
}

.btn-secondary {
  @apply pointer-events-auto rounded-md bg-zinc-300 py-2 px-3 leading-5 text-black hover:bg-zinc-200;
}

.input {
  @apply bg-gray-50 border border-gray-300 text-black rounded-lg focus:ring-amber-500 focus:border-amber-500 block w-full p-2.5 outline-none;
}

.input-label {
  @apply block mb-2 font-medium text-gray-900 dark:text-gray-300;
}

.tabs-wrapper {
  @apply ml-5 mr-5 border-b border-gray-200;
}

.tabs-content {
  @apply flex flex-wrap -mb-px text-sm font-medium text-center;
}

.tab-button {
  @apply inline-flex p-4 rounded-t-lg border-b-2 border-transparent hover:border-gray-300 cursor-pointer;
}

.tab-button.tab-button-active {
  @apply border-amber-500 active;
}

.icon-link {
  @apply cursor-pointer text-amber-500;
}

.assignment-drop-zone {
  @apply min-w-[300px] min-h-[200px] border-dashed border-2 flex items-center justify-center text-gray-400 text-lg;
}

* {
  font-family: 'PT Serif', serif;
}

@media print {
  body * {
    visibility: hidden;
  }
  .section-to-print, .section-to-print * {
    visibility: visible;
  }
  .section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}

body {
  margin: 0;
}

svg {
  display: inline-block;
}

#root {
  position: fixed;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  touch-action: none;
}

.link, #logo {
  text-decoration: none;
  color: inherit;
}

/* index */

header #logo {
  font-size: 4rem;
  font-weight: bold;
  cursor: pointer;
  padding: 20px 0 0 0;
  text-align: center;
  font-family: 'Dancing Script', cursive;
}

#tiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
  justify-content: center;
  margin-top: 10%;
  overflow: auto;
}

#tiles .link {
  @apply hover:bg-amber-300;

  aspect-ratio: 1;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  transition: all 500ms;
  max-width: 220px;
  width: calc(100% - 20px);
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  box-sizing: border-box;
}

.error-content {
  margin-top: 3rem;
  width: 100%;
  text-align: center;
  font-size: 2rem;
}

@media screen and (min-width: 400px) {
  #tiles .link {
      width: calc(50% - 20px);
  }
}

@media screen and (min-width: 800px) {
  #tiles .link {
      width: calc(25% - 20px);
  }
}

@media screen and (min-width: 800px) {
  #tiles .link {
    width: calc(25% - 20px);
  }
}

#tiles .link span {
  font-size: 1.75rem;
  user-select: none;
  text-align: center;
}

.heading {
  margin: 0 3rem;
}

.file-list {
  @apply flex flex-col my-2 mx-5 overflow-y-auto;
}

.file-list-empty {
  @apply text-center;
}

.file-list .file-info {
  display: flex;
}

.file-list .file-info:nth-child(odd) {
  background-color: #f9f9f9;
}

.file-list .file-icon {
  min-width: 3rem;
  flex-basis: 3rem;
  padding: 0.5rem;
  align-self: center;
}

.file-list .file-link {
  /* @apply text-amber-500; */

  flex-basis: 60%;
  padding: 0.5rem;
  align-self: center;
  text-decoration: none;
  flex-grow: 1;
  word-break: break-all;
}

.file-list .file-size {
  flex-basis: 30%;
  text-align: right;
  padding: 0.5rem;
  align-self: center;
}

.file-list .file-action {
  text-align: right;
  padding: 0.5rem;
  align-self: center;
}

/* common */

#nav {
  gap: 10px;
  padding: 5px 10px 5px 0;
  min-height: 70px;
}

#nav #login-btn {
  margin-left: auto;
}

#nav #logo {
  margin: 0 15px;
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
  padding: 10px;
  user-select: none;
  font-family: 'Dancing Script', cursive;
}

#nav .link {
  font-size: 1.5rem;
  cursor: pointer;
  padding: 10px 5px 5px;
  user-select: none;
  white-space: nowrap;
}

#nav .link.active {
  @apply border-b-amber-500 border-b-2 border-solid;
}

#nav .link:hover {
  font-size: 1.5rem;
  cursor: pointer;
}

/* pexeso */

.game-pexeso #tiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  padding: 20px;
  justify-content: center;
  margin-top: 10%;
  overflow: auto;
}

.game-pexeso #tiles .tile {
  @apply hover:bg-amber-300;

  aspect-ratio: 1;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  transition: all 500ms;
  max-width: 220px;
  width: calc(100% - 20px);
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
}

@media screen and (min-width: 400px) {
  .game-pexeso #tiles .tile {
      width: calc(50% - 20px);
  }
}

@media screen and (min-width: 800px) {
  .game-pexeso #tiles .tile {
      width: calc(25% - 20px);
  }
}

.game-pexeso #tiles .tile span {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 1.75rem;
  transform: translate(-50%, -50%);
  user-select: none;
}

.game-pexeso #board-wrapper {
  display: none;
  position: relative;
  flex-grow: 1;
}

.game-pexeso #board {
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
  resize: both;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  /* border: 2px dashed #bb7d05; */
}

.game-pexeso .tile {
  perspective: 1000px;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.game-pexeso .tile .content {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 15px rgba(0,0,0,0.1);
  transition: transform 1s;
  transform-style: preserve-3d;
}

.game-pexeso .tile.faded {
  opacity: 0;
  transition: opacity 0.5s;
}

.game-pexeso .tile.flipped .content {
  transform: rotateY(180deg);
  transition: transform 0.5s;
}

.game-pexeso .tile .front, .tile .back {
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 5px;
  backface-visibility: hidden;
}

.game-pexeso .tile .back {
  transform: rotateY(180deg);
}

/* match */

.game-match {
  overscroll-behavior: contain;
  touch-action: none;
}

.game-match #tiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  padding: 20px;
  justify-content: center;
  margin-top: 10%;
}

.game-match #tiles .tile {
  @apply hover:bg-amber-300;

  aspect-ratio: 1;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  transition: all 500ms;
  max-width: 220px;
  width: calc(100% - 20px);
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
}

@media screen and (min-width: 400px) {
  .game-match #tiles .tile {
      width: calc(50% - 20px);
  }
}

@media screen and (min-width: 800px) {
  .game-match #tiles .tile {
      width: calc(25% - 20px);
  }
}

@media screen and (min-width: 800px) {
  .game-match #tiles .tile {
      width: calc(25% - 20px);
  }
}

.game-match #tiles .tile span {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 1.75rem;
  transform: translate(-50%, -50%);
  user-select: none;
}

.game-match #board-wrapper {
  display: none;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  min-height: 0;
}

.game-match #words-wrapper {
  justify-content: flex-end;
  align-items: flex-end;
}

.game-match #words {
  display: flex;
  flex-direction: column;
  border-radius: 20%;
  border: 4px solid black;
  width: 90%;
  height: 90%;
  justify-content: center;
}

.game-match #words .word {
  display: none;
  margin: 0 10px;
}

.game-match #words .check-mark {
  text-align: center;
  font-size: 5rem;
  font-weight: bold;
  color: #008000;
}

.game-match .word.ghost-text {
  font-size: 2rem;
  cursor: pointer;
  user-select: none;
  padding: 5px;
  background: white;
  border: 1px solid black;
  white-space: nowrap;
  box-sizing: border-box;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.game-match #board {
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  user-select: none;
  /* border: 2px dashed #bb7d05; */
}

.game-match .tile {
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* align-self: baseline; */
}

.game-match .tile img {
  max-width: 100%;
  height: 80%;
}

.game-match .drop {
  height: 20%;
  max-height: 50px;
  width: 100%;
  background-color: white;
  border: 1px solid black;
  position: relative;
}

.game-match .drop span {
  font-size: 2rem;
  user-select: none;
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-height: 750px) {
  .game-match .word.ghost-text {
      font-size: 1.5rem;
  }

  .game-match .drop span {
      font-size: 1.5rem;
  }
}

@media screen and (max-width: 1100px) {
  .game-match .word.ghost-text {
      font-size: 1.5rem;
  }

  .game-match .drop span {
      font-size: 1.5rem;
  }
}

/* match pnguins */

.game-penguins {
  touch-action: none;
  overscroll-behavior: contain;
}

.game-penguins .penguin {
  max-height: 18vw;
  display: inline-block;
  margin: 0.5rem;
}

.game-penguins #board-wrapper {
  height: 100%;
  width: 100%;
  overflow: auto;
  padding: 1rem;
  min-width: 0;
}

.game-penguins #board-wrapper #board {
  position: relative;
  max-width: 80%;
  height: 100%;
}

.game-penguins #board-wrapper #grid {
  height: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  aspect-ratio: 1;
  max-width: 100%;
  grid-auto-rows: min-content;
  touch-action: none;
}

.game-penguins #board-wrapper #grid .grid-item {
  border-left: 1px solid black;
  border-top: 1px solid black;
  align-items: center;
  justify-content: center;
  display: flex;
  aspect-ratio: 1;
}

.game-penguins #board-wrapper #grid .grid-item.img {
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
}

.game-penguins #board-wrapper #grid .grid-item.img.pen {
  background-size: 50%;
}

.game-penguins #board-wrapper #grid .grid-item:nth-last-child(-n+4)  {
  border-bottom: 1px solid black;
}

.game-penguins #board-wrapper #grid .grid-item:nth-child(4n)  {
  border-right: 1px solid black;
}

.game-penguins #penguins {
  flex-grow: 1;
  min-width: 20%;
  padding-left: 1rem;
  touch-action: none;
  user-select: none;
}

.game-penguins #penguins .penguin {
  display: none;
}

/*

.game-penguins #tiles {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  padding: 20px;
  justify-content: center;
  margin-top: 10%;
}

.game-penguins #tiles .tile {
  @apply hover:bg-amber-300;

  aspect-ratio: 1;
  border-radius: 5px;
  position: relative;
  cursor: pointer;
  transition: all 500ms;
  max-width: 220px;
  width: calc(100% - 20px);
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
}

@media screen and (min-width: 400px) {
  .game-penguins #tiles .tile {
      width: calc(50% - 20px);
  }
}

@media screen and (min-width: 800px) {
  .game-penguins #tiles .tile {
      width: calc(25% - 20px);
  }
}

@media screen and (min-width: 800px) {
  .game-penguins #tiles .tile {
      width: calc(25% - 20px);
  }
}

.game-penguins #tiles .tile span {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 1.75rem;
  transform: translate(-50%, -50%);
  user-select: none;
}

.game-penguins #board-wrapper {
  display: none;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  min-height: 0;
}

.game-penguins #words-wrapper {
  justify-content: flex-end;
  align-items: flex-end;
}

.game-penguins #words {
  display: flex;
  flex-direction: column;
  border-radius: 20%;
  border: 4px solid black;
  width: 90%;
  height: 90%;
  justify-content: center;
}

.game-penguins #words .word {
  display: none;
}

.game-penguins #words .check-mark {
  text-align: center;
  font-size: 5rem;
  font-weight: bold;
  color: #008000;
}

.game-penguins #words .word:nth-child(1) {
  display: block;
  margin: 0 10px;
}

.game-penguins #words .word,
.game-penguins #word-ghost {
  font-size: 2rem;
  cursor: pointer;
  user-select: none;
  padding: 5px;
  background: white;
  border: 1px solid black;
  white-space: nowrap;
  box-sizing: border-box;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.game-penguins #word-ghost {
  position: absolute;
}

.game-penguins #board {
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
}

.game-penguins .tile {
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.game-penguins .tile img {
  max-width: 100%;
  height: 80%;
}

.game-penguins .drop {
  height: 20%;
  max-height: 50px;
  width: 100%;
  background-color: white;
  border: 1px solid black;
  position: relative;
}

.game-penguins .drop span {
  font-size: 2rem;
  user-select: none;
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-height: 750px) {
  .game-penguins #words .word,
  .game-penguins #word-ghost {
      font-size: 1.5rem;
  }

  .game-penguins .drop span {
      font-size: 1.5rem;
  }
}

@media screen and (max-width: 1100px) {
  .game-penguins #words .word,
  .game-penguins #word-ghost {
      font-size: 1.5rem;
  }

  .game-penguins .drop span {
      font-size: 1.5rem;
  }
}
*/

/* ulohy */

.game-xmasms #board-wrapper, .game-xmaszs #board-wrapper {
  display: none;
  position: relative;
  flex-grow: 1;
}

.game-xmasms #board, .game-xmaszs #board {
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  box-sizing: border-box;
  resize: both;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  /* border: 2px dashed #bb7d05; */
}

.game-xmasms .tile, .game-xmaszs .tile {
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  display: flex;
  border: 2px solid black;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  border: 15px solid gray;
  perspective: 800px;
  transform-style: preserve-3d;
  background: white;
}

.game-xmasms .tile.active, .game-xmaszs .tile.active {
  border: 15px solid #71522A;
}

.game-xmasms .tile .left, .game-xmasms .tile .right, .game-xmaszs .tile .left, .game-xmaszs .tile .right {
  position: absolute;
  width: 50%;
  height: 100%;
  transform: translateZ(1px);
  text-align: center;
  color: #6a6a6a;
  background: #a8a8a8;
  border: 2px solid gray;
}

.game-xmasms .tile .left, .game-xmaszs .tile .left {
  left: 0;
  top: 0;
}

.game-xmasms .tile .right, .game-xmaszs .tile .right {
  right: 0;
  top: 0;
}

.game-xmasms .tile.open.zoom, .game-xmaszs .tile.open.zoom {
  position: absolute;
  transition: all 3s ease;
}

.game-xmasms .tile.open, .game-xmaszs .tile.open {
  z-index: 10;
}

.game-xmasms .tile.open .left, .game-xmaszs .tile.open .left {
  left: 0;
  top: 0;
  transform-origin: 0 0;
  transform: rotateY(-170deg) translateZ(1px);
  transition: transform 1s ease;
}

.game-xmasms .tile.open .right, .game-xmaszs .tile.open .right {
  right: 0;
  top: 0;
  transform-origin: 100% 0;
  transform: rotateY(170deg) translateZ(1px);
  transition: transform 1s ease;
}

.game-xmasms .tile.active .left, .game-xmasms .tile.active .right, .game-xmaszs .tile.active .left, .game-xmaszs .tile.active .right {
  background: #c0ecf7;
  color: black;
  border: 2px solid black;
}

.game-xmasms .tile .task, .game-xmaszs .tile .task {
  padding: 20px;
}

.game-xmasms .tile .task p, .game-xmaszs .tile .task p {
  font-size: 16pt;
}

.game-xmasms .tile .task h2, .game-xmaszs .tile .task h2 {
  font-size: 20pt;
}

.game-xmasms .tile .task img, .game-xmaszs .tile .task img {
  max-width: 100%;
}

.game-xmasms .tile .task a, .game-xmaszs .tile .task a {
  @apply cursor-pointer text-amber-500;
  font-size: 16pt;
}

/* match-animals */

.game-match-animals {
  touch-action: none;
  overscroll-behavior: contain;
}

.game-match-animals #board-wrapper {
  display: none;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  min-height: 0;
}

.game-match-animals #board .animals {
  display: flex;
  flex-direction: column;
  border: 2px solid black;
  width: 25%;
  height: 90%;
  justify-content: center;
}

.game-match-animals #board .category {
  display: flex;
  flex-direction: column;
  border: 2px solid black;
  width: 25%;
  height: 90%;
}
.game-match-animals #board .category .title {
  padding: 1rem;
  font-size: 1.5rem;
  text-align: center;
}

.game-match-animals #board .category .cont {
  text-align: center;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  height: 100%;
}

.game-match-animals #board .category .cont img {
  flex: 1 1 auto;
  max-width: 45%;
  min-width: 20px;
  padding: 1%;
}

.game-match-animals #board .animals .animal {
  display: none;
  user-select: none;
  padding: 5%;
}

.game-match-animals #board .animals .animal,
.game-match-animals #shape-ghost {
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.game-match-animals #shape-ghost {
  position: absolute;
}

.game-match-animals #board {
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  user-select: none;
}

/* .game-match-animals .drop {
  height: 20%;
  max-height: 50px;
  width: 100%;
  background-color: white;
  border: 1px solid black;
  position: relative;
} */

/* match-shapes */

.game-match-shapes {
  touch-action: none;
  overscroll-behavior: contain;
}

.game-match-shapes #board-wrapper {
  display: none;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  min-height: 0;
}

.game-match-shapes #board {
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  user-select: none;
}

.game-match-shapes #board #top {
  overflow: hidden;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: row;
}

.game-match-shapes #board #bottom {
  overflow: hidden;
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}

.game-match-shapes #board .shapes {
  display: flex;
  flex-direction: column;
  border: 2px solid black;
  width: 33%;
  height: 98%;
  justify-content: center;
  align-items: center;
}

.game-match-shapes #board .category {
  display: flex;
  flex-direction: column;
  border: 2px solid black;
  width: 33%;
  height: 98%;
}
.game-match-shapes #board .category .title {
  padding: 1rem;
  font-size: 1.5rem;
  text-align: center;
}

.game-match-shapes #board .category .cont {
  text-align: center;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  height: 100%;
}

.game-match-shapes #board .category .cont img {
  flex: 1 1 auto;
  max-width: 23%;
  min-width: 20px;
  margin: 4px;
}

.game-match-shapes #board .shapes .shape {
  display: none;
  user-select: none;
  padding: 5%;
  max-width: 60%;
}

.game-match-shapes #board .shapes .shape,
.game-match-shapes #shape-ghost {
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.game-match-shapes #shape-ghost {
  position: absolute;
}

.game-match-shapes #board .points-label {
  font-size: 2rem;
}

.game-match-shapes #board #points {
  font-size: 3rem;
  font-weight: bold;
}

/* match-chosen */

.game-match-chosen {
  touch-action: none;
  overscroll-behavior: contain;
}

.game-match-chosen #select-game {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.5rem;
  margin: auto;
}

.game-match-chosen #board-wrapper {
  display: none;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  min-height: 0;
}

.game-match-chosen #board {
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  user-select: none;
}

.game-match-chosen #board.hidden {
  display: none;
}

.game-match-chosen #board #top {
  overflow: hidden;
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: row;
}

.game-match-chosen #board #bottom {
  overflow: hidden;
  width: 100%;
  height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}

.game-match-chosen #board .shapes {
  display: flex;
  flex-direction: column;
  border: 2px solid black;
  width: 33%;
  height: 98%;
  justify-content: center;
  align-items: center;
}

.game-match-chosen #board .category {
  display: flex;
  flex-direction: column;
  border: 2px solid black;
  width: 33%;
  height: 98%;
}
.game-match-chosen #board .category .title {
  padding: 0.2rem;
  font-size: 3rem;
  text-align: center;
  font-weight: bold;
}

.game-match-chosen #board .category .cont {
  text-align: center;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  align-content: flex-start;
  height: 100%;
}

.game-match-chosen #board .category .cont img {
  flex: 1 1 auto;
  max-width: 23%;
  min-width: 20px;
  margin: 4px;
}

.game-match-chosen #board .shapes .shape {
  display: none;
  user-select: none;
  padding: 5%;
  max-width: 60%;
}

.game-match-chosen #board .shapes .shape,
.game-match-chosen #shape-ghost {
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.game-match-chosen #shape-ghost {
  position: absolute;
}

.game-match-chosen #board .points-label {
  font-size: 2rem;
}

.game-match-chosen #board #points {
  font-size: 3rem;
  font-weight: bold;
}

/* truefalse */

.game-truefalse {
  overscroll-behavior: contain;
  touch-action: none;
}

@media screen and (min-width: 400px) {
  .game-truefalse #tiles .tile {
    width: calc(50% - 20px);
  }
}

@media screen and (min-width: 800px) {
  .game-truefalse #tiles .tile {
    width: calc(25% - 20px);
  }
}

@media screen and (min-width: 800px) {
  .game-truefalse #tiles .tile {
    width: calc(25% - 20px);
  }
}

.game-truefalse #board-wrapper {
  display: none;
  position: relative;
  flex-grow: 1;
  flex-direction: row;
  min-height: 0;
}

.game-truefalse #board {
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  height: 100%;
  user-select: none;
  /* border: 2px dashed #bb7d05; */
}

.game-truefalse #board #current-image {
  max-width: 100%;
	max-height: 100%;
}

.game-truefalse #board #img-true,
.game-truefalse #board #img-false {
  position: absolute;
	left: 0;
	top: 0;
}

.game-truefalse #board #img-wrong {
  position: absolute;
	z-index: 1000;
	display: none;
}

/* evaluation */

.circle-number {
  position: absolute;
  bottom: 0;
  transform: translateY(30%);
  z-index: 100;
  width: 33%;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px solid black;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}

.circle-number.circle-num-0 {
  background-color: white;
  color: black;
}

.circle-number.circle-num-1 {
  background-color: rgb(200, 249, 179);
  color: black;
}

.circle-number.circle-num-2 {
  background-color: rgb(145, 223, 111);
  color: black;
}

.circle-number.circle-num-3 {
  background-color: rgb(94, 155, 68);
  color: white;
}

.circle-number.circle-num-1.bad {
  background-color: rgb(249, 179, 179);
  color: black;
}

.circle-number.circle-num-2.bad {
  background-color: rgb(223, 111, 111);
  color: black;
}

.circle-number.circle-num-3.bad {
  background-color: rgb(155, 68, 68);
  color: white;
}

.toast-msg {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 1rem;
  background: white;
  font-size: 30px;
  font-weight: bold;
  z-index: 110;
  user-select: none;
}

.sub-tabs {
  border-bottom: 3px solid rgb(251 191 36);
  padding-bottom: 0.5rem;
}

.sub-tab {
  font-size: 20px;
}